// assets/styles/frontend.scss

// customize some Bootstrap variables
$primary: darken(#428bca, 20%);

// the ~ allows you to reference things in node_modules
@import "~bootstrap/scss/bootstrap";

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {
	.container {
		max-width: 750px;
	}
}

@media (max-width: 480px) {
	.row {
		margin-bottom: 30px;
	}
}

// button colors
.btn-3 {
	background-color: #57e32c;
}
.btn-3:hover {
	background-color: #45b522;
}

.btn-2 {
	background-color: #b7dd29;
}
.btn-2:hover {
	background-color: #90ad21;
}

.btn-1 {
	background-color: #ffa534;
}
.btn-1:hover {
	background-color: #c47e27;
}

.btn-0 {
	background-color: #ff4545;
}
.btn-0:hover {
	background-color: #d43737;
}

.btn-00 {
	background-color: #42bff5;
}

.btn-00:hover {
	background-color: #26b3f0;
}

.nohover {
	pointer-events: none;
}

.displayNone {
	display: none !important;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 15px;
  border-radius: 5px;  
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%; 
  background: #7393B3;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #7393B3;
  cursor: pointer;
}

.float-left {
	float: left;
}

.float-right {
	float: right;
}

.frageantwort {
	margin-bottom: 20px;
	& label {
		display: inline-block;
		margin-left: 20px;
	}
}

.antwortext {
	width:100%;
}