.audio-player {
    width: 100px;
    height: 100px;

    margin-top: 20px;
    margin-bottom: 20px;

    margin-left: auto;
    margin-right: auto;

    position: relative;
}

.audio-player:hover {
    cursor: pointer;
}

.audio-player-controls {
    position: absolute;

    width: 50px;
    height: 50px;

    left: 25px; 
    top: 25px; 

    text-align: center;
}

.audio-player-button {
    height: 50px;
}

.audio-player-back-ring {
    width: 100px; /*90*/ 
    height: 100px; /*90*/

    top: 0px; /*5*/ 
    left: 0px; /*5*/

    position: absolute;

    background-color: gray;
    border-radius: 50%;
}

.audio-player-back-ring-center {
    width: 80px; /*86*/ 
    height: 80px; /*86*/

    top: 10px; 
    left: 10px;

    position: absolute;

    background-color: white;
    border-radius: 50px;
}

.audio-player-progress-ring {
    width: 100px; 
    height: 100px;

    top: 0px; 
    left: 0px;

    position: absolute;

    border-radius: 50%;

    overflow: hidden;
}

.audio-player-progress-ring-center {
    width: 80px; 
    height: 80px;

    top: 10px; 
    left: 10px;

    position: absolute;

    background-color: white;
    border-radius: 50%;
}

.audio-player-progress-segment {
    position: absolute;
    background-color: #245682;
    width: 50px;
    height: 50px;
}

.audio-player-progress-segment-1 {
    top: 0;
    left: 50px;
    transform-origin: bottom left;

    background: #245682; 
}

.audio-player-progress-segment-2 {
    top: 50px;
    left: 50px;
    transform-origin: top left;

    background: #245682;
}

.audio-player-progress-segment-3 {
    top: 50px;
    left: 0px;
    transform-origin: top right;

    background: #245682;
}

.audio-player-progress-segment-4 {
    top: 0;
    left: 0px;
    transform-origin: bottom left;

    background: #245682;
}

.audio-player-text {
    font-size: 14pt;
    font-weight: normal;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    text-decoration: none;

    color: #245682;

    margin-left: 25px;
}

.audio-player-text:hover {
    cursor: pointer;
}